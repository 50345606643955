<template>
    <v-layout justify-center class="main-functions">
        <v-flex xs12 sm6>
            <v-container fluid grid-list-xl>
                <v-layout row wrap>
                    <v-flex v-for="(b, index) in buttons" xs6 :key="index">
                        <v-badge overlap class="badge-custom" color="deep-purple" bottom>
                            <span slot="badge" v-if="badgeRouterName(b.routeName) && packageStatus > 0">{{ packageStatus }}</span>
                            <v-card :color="b.color" class="clickable white--text" @click="selectFunc(index)">
                                <v-card-title primary-title class="justify-center">
                                    <div class="text-xs-center">
                                        <v-icon size="55px" dark>{{b.icon}}</v-icon>
                                        <div class="headline">{{ $t(b.title) }}</div>
                                    </div>
                                </v-card-title>
                            </v-card>
                        </v-badge>
                        <waiting-dialog :show.sync="dialog" msg="更新資訊中..."></waiting-dialog>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-flex>
    </v-layout>
</template>

<script>
import axios from 'axios'
import store, { HostUrl } from '@/store'
import WaitingDialog from '@/components/WaitingDialog'
import { SideBtnType } from '@/store'
import { getAxiosConfig, getTakeoutFriendUrl } from "@/utils/AuthService"
import i18n from '@/i18n'

export default {
    name: 'MainFunctions',
    components: {
        WaitingDialog
    },
    props: {
        title: {
            type: String,
            default: 'BBox'
        },
        buttons: {
            type: Array,
            required: true
        },
        dialog: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            packageStatus: 0,
            askPackage: 0
        }
    },
    methods: {
        selectFunc: function(index) {
            store.commit('setClientId')
            let b = this.buttons[index]
            this.$router.push({ name: b.routeName })
        },
        badgeRouterName(name) {
            console.log('route name:'+name)
            if (name === 'takeout') return true
            else if (name == 'washClerkTakeout') {
                this.askPackage = 5
                return true
            } else if (name == 'docsClerkTakeout') {
                this.askPackage = 9
                return true
            } else return false
        },
        getPackageStatus() {
            let url = getTakeoutFriendUrl(this.askPackage)
            if (store.getters.user.id === 0 || url === null || url === undefined) {
                return
            }
            let self = this
            axios.get(HostUrl + url, getAxiosConfig())
                .then(function(response) {
                    if (response.data.code === 0) {
                        self.packageStatus = response.data.count
                    } else {
                        self.packageStatus = 0
                    }
                })
                .catch(function(error) {
                    console.log('error', error)
                    self.packageStatus = 0
                })
        }
    },
    activated() {
        store.commit('setTitle', this.title)
        store.commit('setSideBtnType', SideBtnType.Navi)
        if (this.title && (this.title === i18n.t('menu.nav-title') || this.title === i18n.t('menu.nav-washhome-title')
             || this.title === i18n.t('menu.nav-traveldocshome-title'))) {
            this.getPackageStatus()
        }
    },
    deactivated() {
        this.packageStatus = 0
    }
};
</script>

<style scoped>
.main-functions {
    background: url('../assets/main_background.jpg');
    background-size: cover;
    width: 100%;
    height: 100%;
}

.badge-custom {
    display: block;
}
</style>
